<template>
    <BaseSection
        class="section--photo-gallery"
        :class="largeHeader ? 'section--large-header' : ''"
        data-width="none"
        :alignment="headerAlignmentMapped"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <template
            v-if="subTitle"
            v-slot:subtitle
        >
            {{ subTitle }}
        </template>
        <PhotoGallery :items="items" />
    </BaseSection>
</template>

<script>

export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        header() {
            return this.content.header ?? null;
        },
        largeHeader() {
            return this.content.largeHeader ?? true;
        },
        headerAlignment() {
            return this.content.headerAlignment ?? true;
        },
        headerAlignmentMapped() {
            if (this.headerAlignment) {
                return 'center';
            }
            return 'left';
        },
        subTitle() {
            return this.content.subTitle ?? null;
        },
        items() {
            return this.content.photos ?? [];
        }
    }
};
</script>
