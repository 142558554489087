<template>
    <div class="photo-gallery">
        <DragToScroll
            class="photo-gallery__wrapper"
            :snap="false"
        >
            <template
                v-for="(item, index) in items"
            >
                <NavLink
                    v-if="item.linkSingleNoTitle.length"
                    :key="`photo-link-${index}`"
                    :type="mapType(item.linkSingleNoTitle[0].__typename)"
                    :url="mapURI(item.linkSingleNoTitle[0])"
                    :blank="item.linkSingleNoTitle[0].openInNewWindow"
                    class="photo-gallery__item drag-to-scroll__ignore"
                >
                    <ImageAsset
                        :image="item.image[0]"
                        sizes="100vw"
                        :caption="item.caption"
                    />
                </NavLink>
                <span
                    v-else
                    :key="`photo-${index}`"
                    class="photo-gallery__item"
                >
                    <ImageAsset
                        :image="item.image[0]"
                        sizes="100vw"
                        :caption="item.caption"
                        :alt="item.alt"
                    />
                </span>
            </template>
        </DragToScroll>
    </div>
</template>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],

    props: {
        items: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    }
};
</script>

<style lang="less" src="./PhotoGallery.less"></style>
